@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
  width: 340px;
}
.modal {
  position: absolute;
  z-index: 99999999;
}
/* css editor */
.rse-Rule-selector {
  color: #fff !important;
}
.rse-Checkbox-root {
  border: solid 1px #fff !important;
}
.rse-Checkbox-checked:after {
  border: solid 1px #fff !important;
}
.blurry {
  filter: blur(7px);
  -webkit-filter: blur(7px);
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.form-label {
  font-size: 13px;
}
label,
p,
button,
div {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* html,
body {
  position: fixed;
  overflow: hidden;
} */
/* body {
  touch-action: none;
  overscroll-behavior-y: none;
} */
.react-calendar__month-view div div div {
  width: auto !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #1e88e5;
}
body > .mainwrapper {
  width: 100vw;
  height: 100vh !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important; /* enables “momentum” (smooth) scrolling */
}
#root {
  height: auto;
  overflow: auto;
}

.rotate0 {
  transform: rotate(0deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}
.custom-shape-divider {
  position: absolute;

  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: rotate(180deg); invert*/
}
.btn-full {
  width: 100%;
}
.custom-shape-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 106px;
  /* transform: rotateY(180deg); flip*/
}

.custom-control-input:checked ~ .custom-control-label::before {
  border: solid 0px #08bace !important;
  background: #08bace !important;
}

.react-date-picker__inputGroup__input {
  color: #fff;
}

body {
  background-color: #f6f6f6;
  min-height: 100%;
}
.thumbnail {
  margin-top: 10px;
  margin-bottom: 10px;
  /* height: 150px; */
  border-radius: 5px;
}
.navbar-warpper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.flexclass {
  display: flex;
}

.flexclass-768 {
  display: grid;
}

.flexclass-360 {
  display: grid;
}

/* width */
::-webkit-scrollbar {
  /* width: 10px; */
  /* height: 10px; */
  border-radius: 10px;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0bbecd;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #18acd1;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;600&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
body {
  font-family: "Noto Sans", sans-serif;
  text-transform: none;
}
/* 
.form-control {
  padding: 10px;
} */
.form-control-dark::placeholder {
  color: #f5f5f5 !important;
  opacity: 0.3 !important;
}
.form-control-white::placeholder {
  color: #333 !important;
  opacity: 0.3 !important;
}

.form-control-dark:focus {
  box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25);
}

.form-control-dark {
  background: #333;
  color: #fff !important;
  border: solid 1px #000 !important;
}
.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  display: flex;
  background: #333;
  color: #fff !important;
}
.react-time-picker__inputGroup__input {
  flex: 2;
}
.form-control-white {
  background: #f6f6f6;
  color: #000 !important;
  border: solid 1px #fff !important;
}

.form-control-dark:focus {
  background: #333 !important;
  color: #fff !important;
  border: solid 1px #000 !important;
}
.form-control-white:focus {
  background: #f6f6f6 !important;
  color: #000 !important;
  border: solid 1px #fff !important;
}
.nav {
  background: #333 !important;
  border: solid 1px #000 !important;
}

.nav-item {
  background: #333 !important;
  color: #fff !important;
  border: solid 1px #000 !important;
}

.slide-row {
  margin: 0 auto;
  z-index: 999;
  visibility: hidden;
}
.slide-row-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  height: 25px;
  color: #fff;
  margin: 0 auto;
  z-index: 9999 !important;
}
.slide-row-flex-content {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.slide-row-flex-icon {
  font-size: 14;
  color: #fff;
  cursor: pointer;
}
.slide-col {
  margin: 0 auto;
  z-index: 999;
  position: absolute;
  top: 25px;
  visibility: hidden;
}
.slide-col-flex {
  width: 80px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  float: left;
  color: #fff;
  /* width: '140px', height: '40px', display: 'flex',  justifyContent: 'center', alignItems: 'center', float: 'left', background: '#fff', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', padding: '7px 10px', border: 'solid 1px #F9F9F9' */
}
.slide-col-flex-content {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.slide-col-flex-icon {
  font-size: 14;
  color: #fff;
  cursor: pointer;
}
.slide-col-custom {
  margin: 0 auto;
  z-index: 999;
  position: absolute;
  top: 0;
  visibility: visible;
}
.slide-col-custom-flex {
  width: 80px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  float: left;
  color: #fff;
}
.slide-col-custom-flex-content {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.slide-col-custom-flex-icon {
  font-size: 14;
  color: #fff;
  cursor: pointer;
}
.maxwidth {
  width: 100%;
}

.maxwidth-768 {
  width: 740px;
  margin: 0;
}

.maxwidth-360 {
  width: 340px;
  margin: 0;
}

.react-select-container {
  background: pink;
}

.flexrow {
  display: grid;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.flexrow-768 {
  /* width: 760px; */
  display: flex;
  flex-direction: column !important;
  /* margin-left: -30px; */
}

.flexrow-360 {
  /* width: 360px; */
  display: grid;
  flex-direction: column !important;
  /* margin-left: -30px; */
}

.flexColumn {
  flex-direction: column !important;
}

.flex-1 {
  flex: 1;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

.formWrapper {
  width: "100%";
}

.formWrapper768 {
  width: "745px";
  margin-left: 3px;
}

.formWrapper360 {
  width: "350px";
  margin-left: 5px;
}

.countdownWrapper {
  display: flex;
  align-content: center;
  align-items: center;
}

.countdownBox {
  flex: 1;
  margin: 5px;
  padding: 10px;
}

.countdownTitle {
  font-size: 40px;
}

.countdownContent {
  font-size: 20px;
}

.countdownTitle768 {
  font-size: 26px;
}

.countdownContent768 {
  font-size: 18px;
}

.countdownTitle360 {
  font-size: 20px;
}

.countdownContent360 {
  font-size: 15px;
}

.lpcomponentwrapper {
  position: fixed;
  top: 53px;
  right: 54px;
  background-color: #fff;
  width: 240px;
  height: 100%;
  text-align: center;
  overflow-y: scroll;
  z-index: 101;
  box-shadow: 0px 0px 1px 1px rgb(179, 203, 221);
}

.lprow {
  position: fixed;
  bottom: 0;
  background-color: #333;
  right: 54px;
  width: 240px;
}

.accordion > .card {
  overflow: visible;
}

.nav-link,
.list-group-item,
span,
.dropdown-item,
p,
i,
b,
dt,
label,
small {
  font-family: "Open Sans", sans-serif;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs,
.tab-content {
  font-family: "Open Sans", sans-serif;
}

small {
  font-size: 10px;
}

.container-fluid {
  background: #f6f6f6;
}

.navbar {
  padding: 0.5rem 1rem;
}

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 150px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 50px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 85px;
  font-style: italic;

  opacity: 0.5;
  display: block;
  font-size: 18px;
  padding-top: 20px;
}

.icon-menu {
  font-size: 18px;
  color: #b3cbdd;
  margin-right: 10px;
  margin-left: 5px;
}

.section input[type="radio"],
.section input[type="checkbox"] {
  display: none;
}

.mobile-only {
  display: none;
}

.radio-container {
  margin-bottom: 10px;
}

.radio-container label {
  position: relative;
}

.radio-container span::before,
.radio-container span::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.radio-container span.radio:hover {
  cursor: pointer;
}

.radio-container span.radio::before {
  left: -52px;
  width: 45px;
  height: 25px;
  background-color: #a8aac1;
  border-radius: 50px;
}

.radio-container span.radio::after {
  left: -49px;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  background-color: #6c788a;
  transition: left 0.25s, background-color 0.25s;
}

input[type="radio"]:checked + label span.radio::after {
  left: -27px;
  background-color: #ebff43;
}

.radio-container span.checkbox::before {
  width: 27px;
  height: 27px;
  background-color: #fff;
  left: -35px;
  box-sizing: border-box;
  border: 3px solid transparent;
  transition: border-color 0.2s;
}

.radio-container span.checkbox:hover::before {
  border: 3px solid #f0ff76;
}

.radio-container span.checkbox::after {
  content: "\f00c";
  font-family: "FontAwesome";
  left: -31px;
  top: 2px;
  color: transparent;
  transition: color 0.2s;
}

input[type="checkbox"]:checked + label span.checkbox::after {
  color: #62afff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f3b773;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: "#fff";
}

#invoice-top {
  min-height: 120px;
}
#invoice-mid {
  min-height: 120px;
}
#invoice-bot {
  min-height: 250px;
}

.info {
  display: block;
  float: left;
  margin-left: 20px;
}
.title {
  float: right;
}
.title p {
  text-align: right;
}
#project {
  margin-left: 52%;
}
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 5px 0 5px 15px;
  border: 1px solid #eee;
}
.tabletitle {
  padding: 5px;
  background: #eee;
}
.service {
  border: 1px solid #eee;
}
.item {
  width: 50%;
}
.itemtext {
  font-size: 0.9em;
}

#legalcopy {
  margin-top: 30px;
}

.legal {
  width: 70%;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: #f6f6f6;
  border: solid 1px #dfdfdf;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  transition: 0.2s;
  background: #2e3159;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 0px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.nav-tabs {
  background-color: #f5f5f5;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
}

.nav-item {
  margin-right: 0;
}

.tab-content {
  /* background-color: #fff; */
  padding: 25px;
}

#sidebarMenu .card-header {
  padding: 0;

  background-color: #262b40;
  color: #b3cbdd;
}

#sidebarMenu .btn-link {
  color: #b3cbdd;
}

#sidebarMenu .btn {
  padding-top: 2px;
}

#sidebarMenu .card-body {
  padding: 0;
  background-color: #262b40;
}

#sidebarMenu .card {
  border: 0px;
  background-color: #262b40;
  padding-left: 10px !important;
}

#sidebarMenu .nav-link {
  padding: 0px;
}

.btn-link {
  text-decoration: none !important;
  text-transform: none;
  font-size: 14px;
  font-weight: regular;
  padding-left: 5px !important;
}

.nav-item {
  background: #fff;
}

.nav {
  background: #fff;
}

.btn-primary,
.btn-primary:hover {
  background-color: #f3b773;
  border-radius: 18px;
  padding: 8px 20px;
}

.rounded-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 4px;
}

/* On screens that are 992px or less */
@media screen and (max-width: 992px) {
  .flexclass {
    display: grid;
  }

  main {
    width: 100% !important;
    padding-left: 0px !important;
  }

  #style-1::-webkit-scrollbar {
    /* width: 10px; */
  }

  body {
    overflow: hidden;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 900px) {
  .flexclass {
    display: grid;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: inline;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .flexclass {
    display: grid;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: inline;
  }

  .btn-mobile {
    margin-top: 10px;
    width: 100% !important;
  }

  .lpcomponentwrapper {
    width: 250px;
  }

  .lprow {
    width: 250px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 378px) {
  .flexclass {
    display: grid;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: inline;
  }

  .btn-mobile {
    margin-top: 10px;
    width: 100% !important;
  }

  .lpcomponentwrapper {
    width: 250px;
  }

  .lprow {
    width: 250px;
  }
}

.modal-dialog {
  max-width: 900px;
  margin: 1.75rem auto;
}

.rdrDateRangePickerWrapper {
  width: 100% !important;
}

.rdrDefinedRangesWrapper {
  width: 30% !important;
}

.rdrCalendarWrapper {
  width: 70% !important;
}

.lpcomponent-box {
  border: dashed 0px red;
  position: relative;
}

.lpcomponent-box:hover {
  border: dashed 1px purple;
  cursor: pointer;
}

.lpcomponent-box-action {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
}

.lpcomponent-box-action-card {
  visibility: visible;
}

.lpcomponent-box:hover .lpcomponent-box-action-card {
  visibility: visible;
}

.lpcomponent-box:hover .lpcomponent-box-action {
  visibility: visible;
}

.action-btn-icon {
  cursor: pointer;
  z-index: 100;
  text-align: center;
  background-color: red;
  color: #fff;
  position: relative;
  z-index: 99999;
}

.action-btn-icon:hover {
}
.mask1 {
  -webkit-mask-image: url(https://d3vqnu8iq57j2l.cloudfront.net/1699273026993-masklast.png);
  mask-image: url(https://d3vqnu8iq57j2l.cloudfront.net/1699273026993-masklast.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.tab-content {
  padding: 0px 15px !important;
}

.lpcomponentwrapper .card {
  border: 0px !important;
}

.parallax {
  /* The image used */
  /* background-image: url("img_parallax.jpg"); */

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shape-outer {
  display: flex;
  flex-shrink: 0;
  margin: 25px;
}

.shape-inner {
  background-size: cover;
  margin: auto;
}

.pentagon {
  -webkit-clip-path: polygon(50% 0%, 100% 40%, 82% 100%, 18% 100%, 0% 40%);
  clip-path: polygon(50% 0%, 100% 40%, 82% 100%, 18% 100%, 0% 40%);
}

.hexagon {
  -webkit-clip-path: polygon(
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%,
    0% 50%
  );
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.heptagon {
  -webkit-clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 62%,
    75% 100%,
    25% 100%,
    0% 62%,
    10% 20%
  );
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 62%,
    75% 100%,
    25% 100%,
    0% 62%,
    10% 20%
  );
}

.octagon {
  -webkit-clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
}

.nonagon {
  -webkit-clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
}

.decagon {
  -webkit-clip-path: polygon(
    50% 0%,
    80% 10%,
    100% 35%,
    100% 65%,
    80% 90%,
    50% 100%,
    20% 90%,
    0 65%,
    0 35%,
    20% 10%
  );
  clip-path: polygon(
    50% 0%,
    80% 10%,
    100% 35%,
    100% 65%,
    80% 90%,
    50% 100%,
    20% 90%,
    0 65%,
    0 35%,
    20% 10%
  );
}

.circle {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.ellipse {
  -webkit-clip-path: ellipse(35% 50% at 50% 50%);
  clip-path: ellipse(35% 50% at 50% 50%);
}

.triangle {
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.rhombus {
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.trapezoid {
  -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

.parallelogram {
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.bevel {
  -webkit-clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
  clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
}

.rabbet {
  -webkit-clip-path: polygon(
    0% 15%,
    15% 15%,
    15% 0%,
    85% 0%,
    85% 15%,
    100% 15%,
    100% 85%,
    85% 85%,
    85% 100%,
    15% 100%,
    15% 85%,
    0% 85%
  );
  clip-path: polygon(
    0% 15%,
    15% 15%,
    15% 0%,
    85% 0%,
    85% 15%,
    100% 15%,
    100% 85%,
    85% 85%,
    85% 100%,
    15% 100%,
    15% 85%,
    0% 85%
  );
}

.sign-left {
  -webkit-clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
  clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
}

.sign-right {
  -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.chevron-left {
  -webkit-clip-path: polygon(
    100% 0%,
    75% 50%,
    100% 100%,
    25% 100%,
    0% 50%,
    25% 0%
  );
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.chevron-right {
  -webkit-clip-path: polygon(
    75% 0%,
    100% 50%,
    75% 100%,
    0% 100%,
    25% 50%,
    0% 0%
  );
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.container-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  gap: 10px;
  grid-template-rows: masonry;
}

.container-grid-768 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  gap: 5px;
  grid-template-rows: masonry;
}

.container-grid-360 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  gap: 10px;
  grid-template-rows: masonry;
  /* position: "fixed"; */
}

.container-grid img {
  max-width: 100%;
  display: block;
}

.container-grid-768 img {
  max-width: 350px;
  display: block;
  margin-left: 15px;
}

.container-grid-360 img {
  max-width: 340px;
  display: block;
  margin-left: 10px;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.2em 0.5em;
  justify-self: start;
}

.landscape {
  grid-column-end: span 2;
}

/*here */
.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  background-clip: padding-box;
  padding: 5px;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 5px;
}

@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }

  .icon-menu {
    font-size: 16px;
  }
}

/* On screens that are 360px or less */
@media screen and (max-width: 360px) {
  .flex-1 {
    flex: 12;
  }

  .flex-10 {
    flex: 12;
  }

  .flex-11 {
    flex: 12;
  }

  .flex-12 {
    flex: 12;
  }

  .flex-2 {
    flex: 12;
  }

  .flex-3 {
    flex: 12;
  }

  .flex-4 {
    flex: 12;
  }

  .flex-5 {
    flex: 12;
  }

  .flex-6 {
    flex: 12;
  }

  .flex-7 {
    flex: 12;
  }

  .flex-8 {
    flex: 12;
  }

  .flex-9 {
    flex: 12;
  }
}

/* On screens that are 768px or less */
@media screen and (max-width: 768px) {
  /* .landingpage-title {
    display: none;
  } */
  /* .slide-row {
    visibility: visible;
  } */
  #root {
    height: auto;
    /* overflow: hidden !important; */
  }
  .slide-row-flex {
    height: 30px;
  }
  .slide-row-flex-icon {
    font-size: 16px;
  }

  /* .slide-col {
    visibility: visible;
  } */
  .slide-col-flex {
    height: 30px;
  }
  .slide-col-flex-icon {
    font-size: 16px;
  }
  .flexrow {
    display: grid;
    flex-direction: column !important;
  }
  .icon-menu {
    font-size: 14px;
  }

  .flexrow {
    display: flex;
    flex-direction: column !important;
  }

  .flex-1 {
    flex: 12;
  }

  .flex-10 {
    flex: 12;
  }

  .flex-11 {
    flex: 12;
  }

  .flex-12 {
    flex: 12;
  }

  .flex-2 {
    flex: 12;
  }

  .flex-3 {
    flex: 12;
  }

  .flex-4 {
    flex: 12;
  }

  .flex-5 {
    flex: 12;
  }

  .flex-6 {
    flex: 12;
  }

  .flex-7 {
    flex: 12;
  }

  .flex-8 {
    flex: 12;
  }

  .flex-9 {
    flex: 12;
  }
}

.labelContainer {
  height: 30px;
  display: flex;
  width: 300px;
  overflow: hidden;
  position: relative;
  padding: 10px;
}

.labelContainer span {
  height: 30px;
  width: 300px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  transition: 0.6s;
}

.labelContainer:first-of-type:hover span {
  width: auto;
  transform: translateX(calc(300px - 100%));
}

.noborder {
  border: solid 0px #fff;
}

a {
  text-decoration: none;
  color: #000;
}

.btn {
  border: solid 0px #fff;
}
.nav-tabs .nav-link {
  color: #fff;
  border: 0px;
  border-radius: 0px;
}

.mr-3 {
  margin-right: 20px;
}

.lg-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.lg-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.lg-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

/* #bg1 {
  width: 100%;
  height: 100vh;
  background-image: url('./../public/door.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  transition: transform 2s ease; 
  
} */
.leftanimation {
  animation: moveLeft 2s forwards;
}
.rightanimation {
  animation: moveRight 2s forwards;
}
/* #bg2 {
  width: 100%;
  height: 100vh;
  background-image: url('./../public/door.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
 
} */

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-10%)); /* Adjust the value as needed */
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200px); /* Adjust the value as needed */
  }
}

/* invoice */
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}
